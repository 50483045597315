import React from 'react';
import BlackBgMain from '../../moleculas/blackBgMain/BlackBgMain';
function BlackBgMainNosotros(props) {
    return (
        <div id='Nosotros'>
            <BlackBgMain titulo="Nosotros" texto="Somos profesionales de la industria del retail. Llevamos más de 30 años en el mercado de consumo masivo, trabajando al lado de las grandes marcas y siempre con la mirada puesta en el consumidor." styleContainer='containerTodo' texto1="En los últimos años sumamos a nuestro background herramientas de tecnología, lo que nos posibilita dar soluciones más rápidas y eficientes a los problemas del negocio minorista."/> 
        </div>
    );
}

export default BlackBgMainNosotros;