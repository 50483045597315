import React from 'react';
import Card from '../../moleculas/cards/cards';
import "./conjuntoCards.css";
import Diseno from '../../../assets/diseno.svg'
import Develop from '../../../assets/Development.svg'
import Creacion from '../../../assets/contenidoDigital.svg'
function ConjuntoCardsDiseno() {
    return (
        <div className='conjuntoCards'>
            <Card style="cardData" tituloCard="Diseño UX/UI" texto1="Investigación y análisis de usuarios" texto2="Diseño de interfaz de usuarios (UI)" texto3="Arquitectura de la información (IA)" texto4="Prototipos y Pruebas de usabilidad" texto5="Colaboración con desarrolladores" iconValue={Diseno}/>

            <Card style="cardData" tituloCard="Diseño y desarrollo de websites" texto1="Diseño visual del sitio web" texto2="Desarrollo frontend" texto3="Aquitectura y estructura del sitio" texto4="Integración de funcionalidades y herramientas" texto5="Optimización y pruebas"iconValue={Develop}/>

            <Card style="cardData" tituloCard="Creación de contenido digital" texto1="Diseño gráfico creativo" texto2="Diseño de identidad de marca" texto3="Manipulación y retoque de imágenes" texto4="Creación de contenido visual para redes sociales" texto5="Optimización para diferentes medios"iconValue={Creacion}/>
        </div>
    );
}

export default ConjuntoCardsDiseno;