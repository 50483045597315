import React from 'react';
import IconsFooter from '../../moleculas/listanav/IconsFooter';
import ListaFooter from '../../moleculas/listanav/ListaFooter';
import Icon from '../../atomos/botones/Icon';
import Texto from '../../atomos/textos/texto';
import Logo from '../../../assets/logoFooter.webp';
import Email from '../../../assets/mailFooter.webp';
import Linkedin from '../../../assets/linkedinFooter.webp'

function Footer(props) {
    return (
        <div className='Footer'>
        <div className='FooterList'>
            <Icon value={Logo}/>
            <ListaFooter value1='Inicio' value2='Servicios' value3='Nosotros' value4='Clientes'/>
            <IconsFooter valueIcon={Email} valueIcon2={Linkedin}/>
        </div>
        <Texto style='footerAllRigths' value='©2023 PK Soluciones, todos los derechos reservados' />
        </div>
    );
}

export default Footer;