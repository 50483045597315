// import React, { useState, useEffect } from 'react';
// import TextoHeader from '../../moleculas/textoHeader/textoHeader';
// import LogoPK from '../../../assets/headerIMG.webp';

// function Header(props) {
//   const [backgroundColor, setBackgroundColor] = useState('rgba(250, 250, 250, 0.70)');
//   const colors = ['rgba(255, 61, 0, 0.7)', 'rgba(28, 28, 28, 0.9)', 'rgba(255, 255, 255, 0.7)'];
//   const textColors = ['textoHeaderBlanco', 'textoHeaderBlanco', 'textoHeader'];
//   const [currentIndex, setCurrentIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentIndex((prevIndex) => (prevIndex + 1) % colors.length);
//     }, 5000);

//     return () => clearInterval(interval);
//   }, []); 

//   useEffect(() => {
//     setBackgroundColor(colors[currentIndex]);
//   }, [currentIndex]);

//   const textColor = textColors[currentIndex];

//   return (
//     <div className='Header' style={{ backgroundColor }}>
//       <TextoHeader stylecolor={textColor} stylecolor1={textColor}/>
//       <img src={LogoPK} alt="logo-pk-soluciones-retail" />
//     </div>
//   );
// }

// export default Header;



import React, { useState, useEffect } from 'react';
import TextoHeader from '../../moleculas/textoHeader/textoHeader';
import LogoPK from '../../../assets/headerIMG.webp';

function Header(props) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const colors = ['rgba(255, 255, 255, 0.7)', 'rgba(255, 61, 0, 0.7)',  'rgba(28, 28, 28, 0.9)'];
  const textColors = ['textoHeaderNegro', 'textoHeaderBlanco', 'textoHeaderBlanco'];
  const questionColors = ['beige', 'naranja', 'gris'];
  const texts = [
    { 
      text0:"¿Qué hacemos?",
      text1: "Brindamos soluciones modulares rápidas",
      text2: "adaptadas a los problemas operativos acordes a la necesidad de cada retail"
    },
    { 
      text0:"¿Cómo lo hacemos?",
      text1: "Analizamos tu caso con entrevistas.",
      text2: "Evaluamos los puntos de dolor y números globales de tu negocio."
    },
    { 
      text0:"¿Por qué lo hacemos?",
      text1: "Existe una ventaja en operar con procesos correctos y eficientes.",
      text2: "Queremos que mejores tus márgenes y logres una adaptación rápida a los cambios."
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 6000);

    return () => clearInterval(interval);
  }, []); 

  const { text0, text1, text2 } = texts[currentIndex];
  const backgroundColor = colors[currentIndex];
  const textColor = textColors[currentIndex];
  const questionColor = questionColors[currentIndex];

  return (
    <div className='Header' style={{ backgroundColor }}>
      <TextoHeader text0={text0} text1={text1} text2={text2} textColor={textColor} questionColor={questionColor}/>
      <img src={LogoPK} alt="logo-pk-soluciones-retail" />
    </div>
  );
}

export default Header;

