import React from 'react';
import BlackBgMain from '../../moleculas/blackBgMain/BlackBgMain';
function BlackBgMainServicios() {
    return (
        <div id='Servicios'>
            <BlackBgMain titulo="Servicios" texto="Contamos con un completo equipo de profesionales para brindar una variedad de excelentes servicios " styleContainer='containerTodo'/>  
        </div>
    );
}

export default BlackBgMainServicios;