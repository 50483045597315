import React from 'react';
import Texto from '../../atomos/textos/texto';
import Icon from '../../atomos/botones/Icon';
import ListaGlider from '../../atomos/lista/listaGlider';
function CardGlider({valueTexto,valueLista,valueLista1, valueLista2, valueLista3 ,StyleTexto,StyleImg,styleCardGlider,valueImg, styleBoxBussines, styleList1, styleList2, stylelist3}) {
    return (
        <div className={styleCardGlider}>
            <div className={styleBoxBussines}>
                <Icon styleBoxIcon={StyleImg} value={valueImg}/>
            </div>
            <div>
                <ListaGlider valueTexto={valueLista} valueTexto1={valueLista1} valueTexto2={valueLista2} valueTexto3={valueLista3} styleList1={styleList1} styleList2={styleList2} styleList3={stylelist3}/>
            </div>
        </div>
    );
}

export default CardGlider;