import React from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Texto from '../../atomos/textos/texto'
import './listaNav.css'

function ListaFooter({value1, value2, value3, value4}) {
    return (
        <div className='ListaFooterTexto'>
                        
            <ScrollLink
                activeClass="active"
                to="Nav"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >

             <Texto style='textoFooter' value={value1}/>
            </ScrollLink>

            <ScrollLink
                activeClass="active"
                to="Servicios"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >

            <Texto style='textoFooter' value={value2}/>
            </ScrollLink>

            <ScrollLink
                activeClass="active"
                to="Nosotros"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >
                    <Texto style='textoFooter' value={value3}/>
            </ScrollLink>

            <ScrollLink
                activeClass="active"
                to="Clientes"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
            >
                    <Texto style='textoFooter' value={value4}/>
            </ScrollLink>

        </div>
    );
}

export default ListaFooter;