import React from 'react';
import AsesoriaDiseno from '../../moleculas/asesoriaDiseno/asesoriaDiseno';
import "./diseno.css"
function DisenoDesarrollo() {
    return (
        <div className='partners'>
            <AsesoriaDiseno estiloCategoria="cardRoja" tituloCategoria="Diseño UX/UI y desarrollo" persona1nombre="Araceli Pini" persona1puesto="Diseñadora UX/UI" persona2nombre="Francisco Koglot" persona2puesto="Diseñador UX/UI" persona3nombre="Angeles Pini" persona3puesto="Desarrolladora Frontend" className='persona' className1='persona' className2='persona'/>
        </div>
    );
}

export default DisenoDesarrollo;