import React from 'react';
import BlackBgMain from '../../moleculas/blackBgMain/BlackBgMain';

function BlackBgMainClientes(props) {
    return (
        <div id='Clientes'>
            <BlackBgMain titulo="Clientes" texto="Empresas y negocios con los que realizamos trabajos exitosos y construímos relaciones laborales de confianza." styleContainer='containerTodo'/>
        </div>
    );
}

export default BlackBgMainClientes;