import React from 'react';
import './cards.css'
import CardDataContent from '../cardDataContent/cardDataContent';
import Icon from '../../atomos/botones/Icon';
const Card = ({style, tituloCard, texto1,texto2,texto3,texto4,texto5, iconValue}) => {
    return (
        
        <div className={style}>
            
            <div className="icon">
                <Icon value={iconValue}/>                
            </div>

            <div className="texto">
            <CardDataContent tituloCard={tituloCard} texto1={texto1} texto2={texto2} texto3={texto3} texto4={texto4} texto5={texto5}/>
            </div>

        </div>
    );
}

export default Card;