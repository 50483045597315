import * as React from 'react';

import Glider from 'react-glider';
import 'glider-js/glider.min.css';
import CardGlider from '../../moleculas/cards/cardGlider';
import './glider.css';
import Cocacola from '../../../assets/cocacola.webp';
import Minigo from '../../../assets/minigo.webp';
import Danone from '../../../assets/danone.webp';
import Carrefour from '../../../assets/carrefour.png';
import Quick from '../../../assets/quick.webp';
import CMI from '../../../assets/cmi.png';
import Pyg from '../../../assets/pyg.webp';
import Farmacity from '../../../assets/farmacity.webp';
function GliderCarousel() {
    return (
        <div>
            <Glider
            draggable
            hasDots
            slidesToShow={3}
            slidesToScroll={1}
            >
  <div>
    <CardGlider valueLista='Análisis y surtido de planogramas de aguas para Walmart Argentina' valueLista1='Rediseño de layout en bebidas sin alcohol para Libertad Córdoba' valueLista2='Análisis de surtido de la categoría RTD para el mercado argentino' valueLista3='' StyleTexto='encabezadoCard' StyleImg='' styleCardGlider='cardGlider' valueImg={Cocacola} styleBoxBussines='cardImgGlider' stylelist3='displaynone'/>
    </div>

  <div>
    <CardGlider valueLista='Desarrollo del proyecto' valueLista1='Armado de la tienda' valueLista2='Gestión del B2C' valueLista3='Comunicación de la innovación en foros, prensa e inversores' StyleTexto='encabezadoCard' StyleImg='' styleCardGlider='cardGlider' valueImg={Minigo} styleBoxBussines='cardImgGlider'/>
    </div>

  <div>
    <CardGlider valueLista='Análisis de surtido, criterios de exhibición y layout para todas sus categorías en las cadenas Tienda Inglesa y Tata
' valueLista1='' valueLista2='' valueLista3='' StyleTexto='encabezadoCard' StyleImg='' styleCardGlider='cardGlider' valueImg={Danone} styleBoxBussines='cardImgGlider' stylelist3='displaynone' styleList1='displaynone'styleList2='displaynone'/>
    </div>

  <div>
    <CardGlider valueLista='Gestión de la alianza MiniGO - Carrefour' valueLista1='Desarrollo del CatMan de la tienda' valueLista2='Gestión de surtidos, layout y capacitación del personal' valueLista3='' StyleTexto='encabezadoCard' StyleImg='' styleCardGlider='cardGlider' valueImg={Carrefour} styleBoxBussines='cardImgGlider' stylelist3='displaynone'/>
    </div>

  <div>
    <CardGlider valueLista='Elección de los surtidos para cada formato de tienda' valueLista1='Desarrollo de datos del negocio: Reportes de gestión, análisis, rentabilidad' valueLista2='Selección de proveedores, asesoría en negociación' valueLista3='Capacitación a los equipos operativos' StyleTexto='encabezadoCard' StyleImg='' styleCardGlider='cardGlider' valueImg={Quick} styleBoxBussines='cardImgGlider'/>
    </div>

  <div>
    <CardGlider valueLista='Armado de estrategia comercial para las tiendas franquiciadas' valueLista1='Desarrollo del go-to-market para el canal Horeca y food service' valueLista2='Armado de la Estructura Mercadológica y surtido para cada formato de negocio' valueLista3='Desarrollo en conjunto del sistema comercial' StyleTexto='encabezadoCard' StyleImg='' styleCardGlider='cardGlider' valueImg={CMI} styleBoxBussines='cardImgGlider'/>
    </div>

  <div>
    <CardGlider valueLista='Análisis de surtido de categoría pelo para Vea de Cencosud' valueLista1='' valueLista2='' valueLista3='' StyleTexto='encabezadoCard' StyleImg='' styleCardGlider='cardGlider' valueImg={Pyg} styleBoxBussines='cardImgGlider' stylelist3='displaynone' styleList1='displaynone'styleList2='displaynone'/>
    </div>

  <div>
    <CardGlider valueLista='Relevamiento de canal farmacias
' valueLista1='Análisis de surtido y lineamientos de exhibición para la categoría solares ' valueLista2='' valueLista3='' StyleTexto='encabezadoCard' StyleImg='' styleCardGlider='cardGlider' valueImg={Farmacity} styleBoxBussines='cardImgGlider' stylelist3='displaynone'styleList2='displaynone'/></div>


</Glider>
        </div>
    );
}

export default GliderCarousel;