import React from 'react';
import Texto from "../../atomos/textos/texto"
import './blackBgMain.css'

function BlackBgMain({titulo, texto,styleContainer,texto1}) {
    return (
        <div className='BlackBgmain'>
            <div className={styleContainer}>
            <Texto value={titulo} style="tituloWhite"/>
            <Texto value={texto} style="textoWhite"/>    
            <Texto value={texto1} style="textoWhite"/>
            </div>
        
        </div>
    );
}

export default BlackBgMain;