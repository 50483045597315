import React from 'react';
import './listaNav.css';
import Icon from '../../atomos/botones/Icon';
function IconsFooter({valueIcon, valueIcon2}) {
    return (
        <div className='styleBoxFooterIcons'>
            <Icon value={valueIcon}/>
            <Icon value={valueIcon2}/>
        </div>
    );
}

export default IconsFooter;