import React from 'react';
import Texto from '../../atomos/textos/texto';
import Persona from '../../atomos/persona/persona';
import "./asesoriaDiseno.css";

function AsesoriaDiseno({estiloCategoria, tituloCategoria, persona1nombre, persona1puesto, persona2nombre, persona2puesto, persona3nombre,persona3puesto, className, className1, className2}) {
    return (
        <div className='categoriaDisenoAsesoria'>
            <div className={estiloCategoria}>
            <Texto value={tituloCategoria} style="subtituloWhite"/>
            </div>
            <div className="partners">
            <Persona nombre={persona1nombre} puesto={persona1puesto} classname={className}/>
            <Persona nombre={persona2nombre} puesto={persona2puesto} classname={className1}/>
            <Persona nombre={persona3nombre} puesto={persona3puesto} classname={className2}/>
            </div>


        </div>
    );
}

export default AsesoriaDiseno;