import React from 'react';
import Lista from '../../atomos/lista/lista';
import Texto from '../../atomos/textos/texto';
import './cardDataContent.css'

function CardDataContent({tituloCard, texto1,texto2,texto3,texto4,texto5,icon}) {
    return (
        <div className='cardDataContent'>
            <img src={icon} alt="" />
            <Texto value={tituloCard} style="encabezadoCard"/>
            <ul>
                <Lista valueTexto={texto1} style="textoCardClientes"/>
                <Lista valueTexto={texto2} style="textoCardClientes"/>
                <Lista valueTexto={texto3} style="textoCardClientes"/>
                <Lista valueTexto={texto4} style="textoCardClientes"/>
                <Lista valueTexto={texto5} style="textoCardClientes"/>
            </ul>
        </div>
    );
}

export default CardDataContent;