import React from 'react';
import Texto from '../../atomos/textos/texto'
import ConjuntoCardsObjetivos from '../../organismos/conjuntoCards/conjuntoCardsObjetivos';
function Objetivos() {
    return (
        <div className='ListaObjetivosMain'>
            <Texto value='Nuestros objetivos al trabajar' style='subtituloWhite cardNaranjaObjetivos'/>
            <ConjuntoCardsObjetivos/>            
        </div>
    );
}

export default Objetivos;