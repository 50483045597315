import React from 'react';
import Texto from '../../atomos/textos/texto'
import './cards.css'
function CardNumberCliente({style, valueTexto, valueNumber,style1,style2}) {
    return (
        <div className={style}>
            <Texto value={valueNumber} style={style1}/>
            <Texto value={valueTexto} style={style2}/>
        </div>
    );
}

export default CardNumberCliente;