import React from 'react';
import CardsObjetivos from '../../moleculas/cards/cardsObjetivos';
import'./conjuntoCards.css';
import Empoderar from '../../../assets/empoderar.svg';
import Ofrecer from '../../../assets/Ofrecer.svg';
import Brindar from '../../../assets/Brindar.svg';
import Construir from '../../../assets/construir.svg';
import Impulsar from '../../../assets/impulsar.svg';
import Inspirar from '../../../assets/inspirar.svg';

function ConjuntoCardsObjetivos(props) {
    return (
        <div className='listaCardsObjetivos'>
            <CardsObjetivos style='cardsObjetivo' valueTexto='Empoderar negocios y empresas con nuestros conocimientos, experiencias y estrategias efectivas.' valueIcon={Empoderar}/>
            <CardsObjetivos style='cardsObjetivo' valueTexto='Ofrecer un enfoque personalizado comprendiendo a fondo las metas, desafíos y valores de sus negocios.' valueIcon={Ofrecer}/>
            <CardsObjetivos style='cardsObjetivo' valueTexto='Brindarles las soluciones necesarias, adaptadas a sus necesidades específicas, para que lideren su propio camino hacia el éxito.' valueIcon={Brindar}/>
            <CardsObjetivos style='cardsObjetivo' valueTexto='Construír relaciones de confianza duraderas con nuestros clientes. ' valueIcon={Construir}/>
            <CardsObjetivos style='cardsObjetivo' valueTexto='Impulsar la innovación como motor del crecimiento sostenible.' valueIcon={Impulsar}/>
            <CardsObjetivos style='cardsObjetivo' valueTexto='Inspirar a nuestros clientes a adoptar prácticas empresariales revolucionarias que les permitan destacar en entornos comerciales dinámicos.' valueIcon={Inspirar}/>
        </div>
    );
}

export default ConjuntoCardsObjetivos;