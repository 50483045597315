import React from 'react';
import AsesoriaDiseno from '../../moleculas/asesoriaDiseno/asesoriaDiseno';
import './asesoria.css'
function Asesoria(props) {
    return (
        <div className='partners'>
            <AsesoriaDiseno estiloCategoria="cardNaranja" tituloCategoria="Asesoría comercial" persona1nombre="Eduardo Koglot" persona1puesto="Senior Consultant" persona2nombre="Bárbara Paulo" persona2puesto="Category Management Specialist" className='persona' className1='persona' className2=''/>
        </div>
    );
}

export default Asesoria;