import React from 'react';
import Icon from '../../atomos/botones/Icon';
import Listanav from '../../moleculas/listanav/listanav';
import Logo from '../../../assets/logoNav.webp'
function NavBar() {
    return (
        <div className='NavBar' id='Nav'>
            <Icon styleBoxIcon='' value={Logo}/>
            <Listanav style='listaNavMain' value1='Servicios' value2='Nosotros' value3='Clientes' value4='Contacto'/>            
        </div>
    );
}

export default NavBar;