import React from 'react';
import Lista from './lista';
import './lista.css'

function ListaGlider({valueTexto, valueTexto1, valueTexto2, valueTexto3, styleList, styleList1, styleList2, styleList3}) {
    return (
        <>
            <ul className='listaGliderStyles'>
                <Lista valueTexto={valueTexto} styleList={styleList}/>
                <Lista valueTexto={valueTexto1} styleList={styleList1}/>
                <Lista valueTexto={valueTexto2} styleList={styleList2}/>
                <Lista valueTexto={valueTexto3} styleList={styleList3}/>
            </ul>
        </>
    );
}

export default ListaGlider;