import React from 'react';

function Icon({value,styleBoxIcon}) {
    return (
        <div className={styleBoxIcon}>
            <img src={value} alt="" />
        </div>
    );
}

export default Icon;