
import React from 'react';
import Texto from '../../atomos/textos/texto';
import './textoHeader.css';

function TextoHeader({ text0, text1, text2, textColor, questionColor }) {
  return (
    <div className='StyleHeader'>
      <Texto value={text0} style={`tituloHeader ${questionColor}`} />
      <Texto value={text1} style={`tituloHeader ${textColor}`} />
      <Texto value={text2} style={`textoHeader ${textColor}`} />
    </div>
  );
}

export default TextoHeader;


