import React from 'react';
import Asesoria from '../../organismos/Asesoria/Asesoria';
import ConjuntoCardsAsesoria from '../../organismos/conjuntoCards/ConjuntoCardsAsesoria';
import Diseno from '../../organismos/Diseno/DisenoDesarrollo';
import ConjuntoCardsDiseno from '../../organismos/conjuntoCards/conjuntoCardsDiseno';

function AsesoriaDiseno(props) {
    return (
        <div className='AsesoriaDisenoMain'>
            <div className="AsesoriaMain">
                <Asesoria/>
                <ConjuntoCardsAsesoria/>
            </div>
            <div className="DisenoMain">
                <Diseno/>
                <ConjuntoCardsDiseno/>
            </div>
        </div>
    );
}

export default AsesoriaDiseno;