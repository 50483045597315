import React from "react";
import "./App.css"
import {Routes, Route} from "react-router-dom";
import NavBar from "./components/Templates/NavBar/NavBar";
import Header from "./components/Templates/Header/Header"
import MainAll from "./components/Templates/Main/MainAll";
import Footer from "./components/Templates/Footer/Footer";
import Bg from "./components/backgrounds/Bg";


function App() {
  return (
    <div>
      <NavBar/>
      <Bg/>
      <Header/>
      <MainAll/>
      <Footer/>
    </div>
  );
}

export default App;
