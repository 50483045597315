import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";
import './form.css'

const ContactUs = () => {
    const form = useRef();
    let navigate = useNavigate()

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dzzqqxj', 'template_5cci94l', form.current, 'LrE_SrZXE2S2Qymcz')
      .then((result) => {
          console.log(result.text);
          alert('Enviado con éxito')
              e.target.reset()
              navigate('/')
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <form ref={form} onSubmit={sendEmail} className='Form'>
      <input type="text" name="nombreApellido" placeholder='Tu nombre y apellido'/>
      <input type="email" name="email" placeholder='Tu correo electrónico'/>  
      <textarea name="message" placeholder='Contanos brevemente de qué se trata tu consulta...'/>
      <input type="submit" value="Envíanos tu consulta" className='button'/>
    </form>
  );
};

export default ContactUs;