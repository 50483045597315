import React from 'react';
import Texto from '../../atomos/textos/texto';
import './textoHeader.css'
function TextoContacto(props) {
    return (
        <div className='textoContacto'>
            <Texto value="Contactános!" style="tituloBlack"/>
            <Texto value="Hagamos crecer tu negocio juntos." style="tituloBlack"/>
            <Texto value="No desperdicies tu tiempo ni pagues de más, te damos soluciones rápidas y personalizadas a la altura de tu negocio." style="textoBlack"/>
        </div>
    );
}

export default TextoContacto;