import React from 'react';
import './cards.css'
import Texto from '../../atomos/textos/texto'
import Icon from '../../atomos/botones/Icon';
function CardsObjetivos({style, valueTexto, valueIcon}) {
    return (
        <div className={style}>
            <Icon styleBoxIcon='cardIconObjetivo' value={valueIcon}/>
            <div className='cardObjetivos'>
            <Texto style='textoCardTiny' value={valueTexto}/>
            </div>

        </div>
    );
}

export default CardsObjetivos;