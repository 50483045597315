import React from 'react';
import BlackBgMainServicios from '../../organismos/BlackBgMainStyle/BlackBgMainServicios';
import AsesoriaDiseno from './AsesoriaDiseno';
import BlackBgMainNosotros from '../../organismos/BlackBgMainStyle/BlackBgMainNosotros';
import Objetivos from './Objetivos';
import BlackBgMainClientes from '../../organismos/BlackBgMainStyle/BlackBgMainClientes';
import GliderCarousel from '../../organismos/glider/Glider';
import BlackBgNumbres from '../../organismos/BlackBgMainStyle/BlackBgNumbres';
import Contacto from './Contacto';
function MainAll() {
    return (
        <div>
            <BlackBgMainServicios/>
            <AsesoriaDiseno/>
            <BlackBgMainNosotros/>
            <Objetivos/>
            <BlackBgMainClientes/>
            <GliderCarousel/>
            <Contacto/>
            <BlackBgNumbres/>
        </div>
    );
}

export default MainAll;