import React from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Texto from '../../atomos/textos/texto'

import './listaNav.css';
function Listanav({style, value1, value2, value3, value4}) {
    return (
        <div className={style}>

<ScrollLink
        activeClass="active"
        to="Servicios"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <Texto style='textoNav' value={value1}/>
      </ScrollLink>

      <ScrollLink
        activeClass="active"
        to="Nosotros"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
            <Texto style='textoNav' value={value2}/>
      </ScrollLink>

      <ScrollLink
        activeClass="active"
        to="Clientes"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
            <Texto style='textoNav' value={value3}/>
      </ScrollLink>

      <ScrollLink
        activeClass="active"
        to="Contacto"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >
        <Texto style='textoNav' value={value4}/>
      </ScrollLink>


        </div>
    );
}

export default Listanav;