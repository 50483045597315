import React from 'react';
import TextoContacto from '../../moleculas/textoHeader/TextoContacto';
import ContactUs from '../../moleculas/formulario/form';
function Contacto(props) {
    return (
        <div className='Contacto Header' id='Contacto'>
            <TextoContacto/>
            <ContactUs/>
        </div>
    );
}

export default Contacto;