import React from 'react';
import CardNumberCliente from '../../moleculas/cards/CardNumberCliente';
import './blackBg.css'
function BlackBgNumbres() {
    return (
        <div className='BlackBgmainNumbers'>
            <CardNumberCliente style='cardNaranjaNumber cardNumber' valueTexto='años de experiencia' valueNumber='+35' style1='textoNumerosCards' style2='textoCardNumerosClientes'/>
            <CardNumberCliente style='cardRojaNumber cardNumber' valueTexto='casos de consultoría' valueNumber='+10' style1='textoNumerosCards' style2='textoCardNumerosClientes'/>
            <CardNumberCliente style='cardNaranjaNumber cardNumber' valueTexto='países' valueNumber='+7' style1='textoNumerosCards' style2='textoCardNumerosClientes'/>
            <CardNumberCliente style='cardRojaNumber cardNumber' valueTexto='procesos gestionados' valueNumber='+50' style1='textoNumerosCards' style2='textoCardNumerosClientes'/>
            <CardNumberCliente style='cardNaranjaNumber cardNumber' valueTexto='guest speaker en congresos y eventos' valueNumber='+20' style1='textoNumerosCards' style2='textoCardNumerosClientes'/>
        </div>
    );
}

export default BlackBgNumbres;