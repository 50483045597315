import React from 'react';
import Texto from "../textos/texto.jsx"
const Lista = ({style,valueTexto, styleList}) => {
    return (
        <>
            <li className={styleList}><Texto value={valueTexto} style={style}/></li>
        </>
    );
}

export default Lista;