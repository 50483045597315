import React from 'react';
import './texto.css'
const Titulo = ({value, style, style1}) => {
    return (
        <>
            <p className={style} style={style1}>{value}</p>
        </>
    );
}

export default Titulo;