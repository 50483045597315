import React from 'react';
import Card from '../../moleculas/cards/cards';
import "./conjuntoCards.css";
import Data from '../../../assets/data.svg'
import Negociacion from '../../../assets/negociacion.svg'
import Category from '../../../assets/category.svg'

function ConjuntoCardsAsesoria() {
    return (
        <div className='conjuntoCards'>
            <Card style="cardData" tituloCard="Category Management" texto1="Layouts" texto2="Surtidos eficientes" texto3="Lineamientos de exhibición" texto4="Estructuras mercadológicas" texto5="Análisis de performance" iconValue={Category}/>

            <Card style="cardData" tituloCard="Negociación" texto1="Selección de proveedores" texto2="Estrategias de precios" texto3="Comercio exterior" texto4="Acuerdos comerciales" texto5="Logística" iconValue={Negociacion}/>

            <Card style="cardData" tituloCard="Data analysis y KPI’s" texto1="Definición de indicadores claves del negocio " texto2="Cubos de información" texto3="Reportería " texto4="Análisis de rentabildad final" texto5="Adecuación de procesos" iconValue={Data}/>
        </div>
    );
}

export default ConjuntoCardsAsesoria;