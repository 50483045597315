import React from 'react';
import Texto from '../textos/texto'
import './persona.css';
function persona({nombre, puesto, classname}) {
    return (
        <div className={classname}>
            <Texto value={nombre}style="encabezadoCard"/>
            <Texto value={puesto} style="textoCard"/>            
        </div>
    );
}

export default persona;